@import "./variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.market-product-background {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}